import styled, { css } from 'styled-components';

export interface DividerProps {
  $gradient?: boolean;
}

export const defaultDividerStyles = (dividerProps: DividerProps) => css`
  border: none;
  height: 1px;
  margin-bottom: ${(props) => props.theme.space.default_16};
  background: ${(props) =>
    dividerProps.$gradient
      ? css`linear-gradient(90deg,
            ${props.theme.palette.divider.gradient.start} 0%,
            ${props.theme.palette.divider.gradient.end} 100%)`
      : props.theme.palette.divider.default};
  transition: background-color ${(props) => props.theme.transition.theme};
`;

export const Root = styled.hr<DividerProps>`
  ${(props) => defaultDividerStyles(props)}
`;
